<template>
     <div  v-loading="loading">
      <div class="card card-custom">
        <div class="card-body p-0">        
            <div class="row">           
              <div class="col-sm-6 col-xs-12">
                <b-form-group>
                  <b-input-group size="sm">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      id="filterInput"
                      placeholder="Type to Search"
                      @keyup.enter.native="(filter)?fetchData(1):''"
                    ></b-form-input>
                    <span class="search-icon" v-if="filter" id="clear-button">
                      <i class="fas fa-times" @click="filter =''"></i>
                      <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                    </span>
                  <b-input-group-append>
                      <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-sm-6 col-xs-12 text-right">
                <b-button class="rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="createQuotation"><i class="fa fa-plus"></i>Create Quotation
                </b-button>               
              </div>              
            </div>
             <div class="row">
              <div class="col-sm-12">
                <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">              
                  <el-table-column label="Quotation #" sortable width="130" >
                    <template slot-scope="scope">
                      <el-tag slot="reference" size="mini">{{(scope.row.quotation_no) ? scope.row.quotation_no : ""}}</el-tag>                
                    </template>
                  </el-table-column> 
                     <el-table-column label="Status" sortable width="130" prop = "status" align="center" >
                    <template slot-scope="scope">
                      <span v-if="scope.row.quotation_status == 'Saved'">
                      <span v-if="new Date() > new Date(scope.row.delivery_date) && new Date() == new Date(scope.row.delivery_date)">
                          <el-tag type="danger" size="medium" >Expired</el-tag> 
                        </span>
                        <span v-else>
                            <el-tag type="success" size="medium">{{scope.row.quotation_status }}</el-tag>                            
                        </span>      
                      </span>
                      <span v-else>
                        <el-tag type="success" size="medium" v-if="scope.row.quotation_status == 'Saved'">{{scope.row.quotation_status }}</el-tag>
                        <el-tag size="medium" v-if="scope.row.quotation_status == 'Sent'">{{scope.row.quotation_status }}</el-tag>                
                      </span>       
                    </template>
              </el-table-column> 
                   <el-table-column label="Created" prop="delivery_date"  width="90">
                    <template slot-scope="scope">
                    {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
                    </template>
                  </el-table-column> 
                  <el-table-column label="Request by" prop="quotation_request_by" sortable min-width="150" >
                    <template slot-scope="scope">
                      <span v-if="scope.row.customer.first_name">{{scope.row.customer.first_name}}</span> <span v-if="scope.row.customer.last_name">{{scope.row.customer.last_name}}</span>
                    </template>
                  </el-table-column>
                   <el-table-column label="Store" prop="store"  width="120" >
                    <template slot-scope="scope">
                    {{scope.row.website_details?scope.row.website_details.website_name:""}}
                    </template>
                  </el-table-column>                              
                    <el-table-column label="Ship Via" prop="ship_via"  width="100" >
                      <template slot-scope="scope">
                      {{scope.row.ship_via}} 
                      </template>
                    </el-table-column>
                     <el-table-column label="Term" prop="term"  width="100" >
                      <template slot-scope="scope">
                      {{scope.row.term}} 
                      </template>
                    </el-table-column>
                     <el-table-column label="Sales Person" prop="sales_by" sortable width="150" >
                      <template slot-scope="scope">
                      {{scope.row.quotation_create_by}} 
                      </template>
                    </el-table-column>
                     <el-table-column label=" Total Amount" prop="total"  width="110" >
                      <template slot-scope="scope">
                      ${{scope.row.total}}
                      </template>
                    </el-table-column> 
                     <el-table-column fixed="right" align="center" label="Actions" width="80">
                      <template slot-scope="scope">
                        <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                          <i class="flaticon2-menu-1"></i>
                          <el-dropdown-menu slot="dropdown" class="action-dropdown">
                            <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>                      
                            <el-dropdown-item :command="2" ><i class="fa fa-pen"></i>Edit</el-dropdown-item>
                            <el-dropdown-item :command="3" ><i class="fa fa-envelope"></i>Send Email</el-dropdown-item>
                            <el-dropdown-item :command="4" ><i class="fa fa-download"></i>Download</el-dropdown-item> 
                             <el-dropdown-item :command="6" ><i class="fa fa-file"></i>Convert to Order</el-dropdown-item>
                            <el-dropdown-item :command="5" ><i class="fa fa-trash"></i>Delete</el-dropdown-item> 
                          </el-dropdown-menu>
                        </el-dropdown>
                      </template>
                    </el-table-column>
                </el-table>
              </div>
             </div> 
             <div class="row">
              <div class="col-sm-12">
                <el-pagination v-if="!loading" style="margin-top:15px;"
                    background
                    layout="sizes, prev, pager, next"
                    @size-change="handleSizeChange"
                    @current-change="fetchData" 
                    :page-count="pagination.last_page" 
                    :page-sizes="[50, 100, 500, 1000]"
                    :page-size="pagination.per_page"
                    :current-page.sync="pagination.current_page" 
                    :total="pagination.total_rows"
                    >
                </el-pagination>
              </div>
            </div>   
          </div>
      </div>
     </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllQuotation,downloadPdf,deleteQuotation} from "@/api/quotation";
import SendEmail from '@/components/SendEmail';
import { globalEvents } from '@/utils/globalEvents'
 export default {
    name: "invoice-list",
    data() {
      return {
          loading: false,
          list:[],
          pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 50,
        },     
        view_type:'quotation',
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      },
      
    },
     methods:{
        createQuotation(){
        this.$router.push({path:'/quotation/add'})
      },
       fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
          if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }       
         
          getAllQuotation(params).then(response => {  
              this.list = response.data.data.data;
              this.loading = false;
          });
        

       },
      handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
        handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({path:'/quotation/view/'+row.id})
          }
           else if (evt === 2){
            this.$router.push({path:'/quotation/edit/'+row.id})
          }
           else if (evt === 3){ 
                let customer = row.customer;
                let order_id = row.id;
                let type = this.view_type;
                this.$modal.show(SendEmail, {customer, type, order_id}, 
                  { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          }
          else if (evt === 4){
            this.loading=true;
            downloadPdf(row.id, this.view_type).then(response => {
              //console.log(response.data);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'quotation.pdf');
              document.body.appendChild(link);
              link.click();
              this.loading = false;
            }).catch(err => {
              console.log(err);
              this.loading = false;
            });
          } 
           else if (evt === 5){
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){
                this.loading = true;
               deleteQuotation(row.id).then(response => {
                  if(response.data.status){
                      this.fetchData(1)
                      this.$showResponse('success', response.data.message);
                  }
                  this.loading = false;
                })
              }else{
                return false;
              }
            })
          }  else if(evt === 6){
         this.$bvModal.msgBoxConfirm('Are you sure you want to convert a order?', {
              title: 'Convert this estimation to a Order',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'primary',
              okTitle: 'convert',
              cancelTitle: 'cancel',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){ 
                this.$router.push({path:'/convert/quotation/order/'+row.id})
              }else{
                return false;
              }
            })
      }
      }
     
     },
     created(){
       this.fetchData(1);
     },
     
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Quotation", route: "/quotation/list" },    
      ]);
       globalEvents.$on('mailsend', mail => {         
            if (mail == 'success') {
                this.fetchData(1);
            }
        });
    },
 }
</script>
